/* survey api */
import {
  SurveyModel,
  QuestionModel,
  refineQuestions,
  LogicRuleModel,
  QuestionStatModel,
  UserModel,
  TemplateModel,
} from "./Model";
import {
  surveys as initialSurveys,
  surveyLogicList as initialSurveyLogicList,
  surveyCollectionList,
  statisticsData,
  userList,
  templateList,
} from "./MockData";
import { ApiAdapter } from "./ApiAdapter";
import {
  enableStorage,
  persistToStorage,
  loadFromStorage,
} from "./Utils/Storage";

import TempPreviewMockData from "./TempPreviewMockData";
import { LogicRuleMapToModel } from "./Utils/LogicRuleMap";

enableStorage(true);

const surveys = loadFromStorage("surveys", initialSurveys);
const surveyLogicList = loadFromStorage(
  "surveyLogicList",
  initialSurveyLogicList
);

const Api = new ApiAdapter();

// libs
const deepCopy = require("deepcopy");

// helpers
const _delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const _findSurveyIdx = id => {
  const idx = surveys.findIndex(s => s.id === id);
  if (idx === -1) {
    throw new Error("API fail");
  }
  return idx;
};

// survey apis
export async function _fetchMyAllSurveys() {
  await _delay(200);
  for (let i = 0; i < surveys.length; i++) {
    if (!surveys[i].questions || surveys[i].questions.length === 0) {
      surveys[i].questions.push({
        id: `${new Date().getTime()}.${i}`,
        type: "PAGE",
      });
    }
  }
  return surveys.map(s => new SurveyModel(s));
}
export async function _fetchMySurveys() {
  await _delay(200);
  for (let i = 0; i < surveys.length; i++) {
    if (!surveys[i].questions || surveys[i].questions.length === 0) {
      surveys[i].questions.push({
        id: `${new Date().getTime()}.${i}`,
        type: "PAGE",
      });
    }
  }

  return surveys.map(s => new SurveyModel(s));
}

export async function _fetchTeamSurveys(profile, teamId) {
  await _delay(200);
  for (let i = 0; i < surveys.length; i++) {
    if (!surveys[i].questions || surveys[i].questions.length === 0) {
      surveys[i].questions.push({
        id: `${new Date().getTime()}.${i}`,
        type: "PAGE",
      });
    }
  }

  return surveys.map(s => new SurveyModel(s));
}

export async function _fetchMyOwnSurveys(profile) {
  await _delay(200);
  for (let i = 0; i < surveys.length; i++) {
    if (!surveys[i].questions || surveys[i].questions.length === 0) {
      surveys[i].questions.push({
        id: `${new Date().getTime()}.${i}`,
        type: "PAGE",
      });
    }
  }

  return surveys.map(s => new SurveyModel(s));
}

export async function _fetchSharedSurveys(profile) {
  await _delay(200);
  for (let i = 0; i < surveys.length; i++) {
    if (!surveys[i].questions || surveys[i].questions.length === 0) {
      surveys[i].questions.push({
        id: `${new Date().getTime()}.${i}`,
        type: "PAGE",
      });
    }
  }

  return surveys.map(s => new SurveyModel(s));
}

export async function fetchMyAllSurveys(profile) {
  return Api.querySurveyListForce(profile);
}

export async function fetchMySurveys(
  profile,
  page,
  perPage,
  queryInfo = null,
  search = null
) {
  return Api.querySurveyList(profile, page, perPage, queryInfo, search);
}

export async function fetchTeamSurveys(
  token,
  teamId,
  page,
  perPage,
  queryInfo = null,
  search = null
) {
  return Api.queryTeamSurveyList(
    token,
    teamId,
    page,
    perPage,
    queryInfo,
    search
  );
}

export async function fetchMyOwnSurveys(
  token,
  page,
  perPage,
  queryInfo = null,
  search = null
) {
  return Api.queryMyOwnSurveyList(token, page, perPage, queryInfo, search);
}

export async function fetchSharedSurveys(
  token,
  page,
  perPage,
  queryInfo = null,
  search = null
) {
  return Api.querySharedSurveyList(token, page, perPage, queryInfo, search);
}

export async function _fetchSurveyStatus() {
  await _delay(200);
  return [
    {
      status: "1",
      description: "建立中",
    },
    {
      status: "2",
      description: "主管審核中",
    },
    {
      status: "3",
      description: "執行單位審核中",
    },
    {
      status: "4",
      description: "修改中",
    },
    {
      status: "5",
      description: "已決行",
    },
    {
      status: "6",
      description: "執行中",
    },
    {
      status: "7",
      description: "已完成",
    },
    {
      status: "8",
      description: "已取消",
    },
  ];
}

export async function fetchSurveyStatus() {
  return Api.fetchSurveyStatus();
}

export async function fetchEndingPageById(id) {
  const questionnaireStatus = await Api.fetchEndingPageById(id);
  return questionnaireStatus;
}

// 2022-08-09 ALVIN 歡迎頁APIs
export async function fetchWelcomePageById(id) {
  const questionnaireStatus = await Api.fetchWelcomePageById(id);
  return questionnaireStatus;
}

export async function _fetchById(id, OtherFlag) {
  await _delay(200);
  const idx = _findSurveyIdx(id);
  // TODO: debug only, remove this in production
  window._survey = surveys[idx];
  return new SurveyModel(surveys[idx]);
}

export async function fetchById(id, OtherFlag) {
  const survey = await Api.querySurveyById(id, OtherFlag);
  if (typeof survey == "undefined") return null;
  window._survey = survey;
  return {
    survey: new SurveyModel(survey),
    unModelSurvey: survey,
  };
}

export async function fetchNoPicById(id, OtherFlag) {
  const survey = await Api.querySurveyNoPicById(id, OtherFlag);
  window._survey = survey;
  return new SurveyModel(survey);
}

export async function createSurvey(profile, survey, OtherFlag = true) {
  try {
    const _survey = await Api.querySurveyById(survey.id, OtherFlag);
    window._survey = _survey;
    return new SurveyModel(_survey);
  } catch (err) {
    throw "fail to create";
  }
}

export const _createSurvey = persistToStorage(
  "surveys",
  surveys
)(async function _createSurvey(profile, survey) {
  await _delay(200);
  const _survey = {
    ...surveys[surveys.length - 1],
    questions: [{ id: `${new Date().getTime()}`, type: "PAGE" }],
    title: survey.title,
    id: `${new Date().getTime()}`,
  };

  surveys.push(_survey);
  return new SurveyModel(_survey);
});

export const _updatePropertyById = persistToStorage(
  "surveys",
  surveys
)(async function _updatePropertyById(id, props) {
  await _delay(100);
  const idx = _findSurveyIdx(id);
  surveys[idx] = {
    ...surveys[idx],
    ...props,
  };
});

export async function updatePropertyById(id, props, unModelSurvey) {
  let extraProps = await Api.updatePropertyById(id, props);
  unModelSurvey = {
    ...unModelSurvey,
    ...extraProps,
  };
  return new SurveyModel(unModelSurvey);
}

export const _updateThemePropertyById = persistToStorage(
  "surveys",
  surveys
)(async function _updateThemePropertyById(id, props) {
  await _delay(100);
  const idx = _findSurveyIdx(id);
  surveys[idx] = {
    ...surveys[idx],
    themeConfig: {
      ...surveys[idx].themeConfig,
      ...props,
    },
  };
});

export async function updateThemePropertyById(id, props) {
  await Api.updateThemePropertyById(id, props);
}

// 2022-05-26: 企業版型，前端行為改變，新增此Function
export async function updateThemePropertyByIdAndReturnSurveyModel(
  id,
  props,
  unModelSurvey
) {
  let extraProps = await Api.updateThemePropertyByIdAndReturnSurveyModel(
    id,
    props
  );
  unModelSurvey = {
    ...unModelSurvey,
    ...extraProps,
  };
  return new SurveyModel(unModelSurvey);
}

export const _createQuestionById = persistToStorage(
  "surveys",
  surveys
)(async function _createQuestionById(id, questionType) {
  await _delay(1000);
  const idx = _findSurveyIdx(id);
  const nextQuestion = QuestionModel.getInitialData(questionType);
  surveys[idx].questions.push(nextQuestion);
});

export async function createQuestionById(id, questionType, pageNo) {
  return Api.createQuestionById(id, questionType, pageNo);
}

export async function updateQuestionById(
  id,
  questionIdx,
  questionInst,
  question
) {
  await Api.updateQuestionById(id, questionInst, question);

  // adjust the ordering if need
  let nextQuestions = [...questionInst._survey._data.questions];
  nextQuestions[questionIdx] = {
    ...nextQuestions[questionIdx],
    ...question,
  };

  if (
    nextQuestions[questionIdx].type === "SINGLE_CHOICE" ||
    nextQuestions[questionIdx].type === "MULTIPLE_CHOICE"
  ) {
    nextQuestions = refineQuestions(nextQuestions);
    await Api.reorderQuestionById(id, nextQuestions);
  }
}

export const _updateQuestionById = persistToStorage(
  "surveys",
  surveys
)(async function _updateQuestionById(id, questionIdx, question) {
  await _delay(1000);
  const idx = _findSurveyIdx(id);
  let nextQuestions = surveys[idx].questions;
  if (!nextQuestions[questionIdx]) {
    throw new Error("API fail");
  }

  nextQuestions[questionIdx] = {
    ...nextQuestions[questionIdx],
    ...question,
  };

  // note: move child question under parent question, only SINGLE_CHOICE and MULTIPLE_CHOICE have child question config
  if (
    nextQuestions[questionIdx].type === "SINGLE_CHOICE" ||
    nextQuestions[questionIdx].type === "MULTIPLE_CHOICE"
  ) {
    nextQuestions = refineQuestions(nextQuestions);
  }

  surveys[idx].questions = nextQuestions;
});

export async function deleteQuestionById(id, questionIdx, questionInst) {
  await Api.deleteQuestionById(id, questionInst);
  let nextQuestions = [...questionInst._survey._data.questions];
  nextQuestions.splice(questionIdx, 1);
  nextQuestions = refineQuestions(nextQuestions);
  await Api.reorderQuestionById(id, nextQuestions);
}

export const _deleteQuestionById = persistToStorage(
  "surveys",
  surveys
)(async function _deleteQuestionById(id, questionIdx) {
  await _delay(1000);
  const idx = _findSurveyIdx(id);
  let nextQuestions = surveys[idx].questions;
  if (!nextQuestions[questionIdx]) {
    throw new Error("API fail");
  }

  nextQuestions.splice(questionIdx, 1);
  nextQuestions = refineQuestions(nextQuestions);
  surveys[idx].questions = nextQuestions;
});

export async function copyQuestionById(id, questionIdx, questionInst) {
  const resp = await Api.createQuestionById(id, questionInst.type, 1);
  const qId = resp.data.QuestionId;
  const qSeq = resp.data.QuestionSeq;
  const qData = { ...questionInst._data };
  let updatedSurvey;
  if (["SINGLE_CHOICE", "MULTIPLE_CHOICE"].indexOf(qData.type) !== -1) {
    qData.options.forEach(option => {
      option.connectTo = undefined;
    });
  }
  if (
    ["SINGLE_CHOICE", "MULTIPLE_CHOICE", "MATRIX"].indexOf(qData.type) !== -1
  ) {
    questionInst._data._raw.option.forEach(option => {
      option.OptionId = null;
    });
  }

  await Api.updateQuestionById(id, questionInst, qData, { qId, qSeq });
  updatedSurvey = await Api.querySurveyForCopyById(id, true);
  let simplifyArray = Array.from(updatedSurvey.data.QuestionList, e => {
    return {
      QuestionId: e.QuestionId,
      QuestionSeq: e.QuestionSeq,
    };
  });
  let lastQuestion = simplifyArray.pop();
  simplifyArray.splice(questionIdx, 0, lastQuestion);
  await Api.reorderQuestionForCopyById(id, simplifyArray);
}

export const _copyQuestionById = persistToStorage(
  "surveys",
  surveys
)(async function _copyQuestionById(id, questionIdx) {
  await _delay(1000);
  const idx = _findSurveyIdx(id);
  let nextQuestions = surveys[idx].questions;
  if (!nextQuestions[questionIdx]) {
    throw new Error("API fail");
  }

  const clonedQuestion = {
    ...deepCopy(nextQuestions[questionIdx]),
    id: `${new Date().getTime()}`,
  };

  // note: check for connectedTo and clear it
  if (
    ["SINGLE_CHOICE", "MULTIPLE_CHOICE"].indexOf(clonedQuestion.type) !== -1
  ) {
    clonedQuestion.options.forEach(option => {
      option.connectTo = undefined;
    });
  }

  nextQuestions = [
    ...nextQuestions.slice(0, questionIdx + 1),
    clonedQuestion,
    ...nextQuestions.slice(questionIdx + 1, nextQuestions.length),
  ];

  surveys[idx].questions = refineQuestions(nextQuestions);
});

export async function reorderQuestionById(id, srcIdx, destIdx, surveyInst) {
  const nextSurvey = surveyInst.reorderQuestion(srcIdx, destIdx);
  const nextQuestions = refineQuestions(nextSurvey._data.questions);
  return Api.reorderQuestionById(id, nextQuestions);
}

export const _reorderQuestionById = persistToStorage(
  "surveys",
  surveys
)(async function _reorderQuestionById(id, srcIdx, destIdx) {
  await _delay(1000);
  const idx = _findSurveyIdx(id);
  const nextSurvey = new SurveyModel(surveys[idx]).reorderQuestion(
    srcIdx,
    destIdx
  );
  surveys[idx].questions = refineQuestions(nextSurvey._data.questions);
});

export async function removePageById(id, beginIdx, endIdx, surveyInst) {
  if (beginIdx === 0) {
    return; // shouldn't remove first page
  }

  return Api.deleteQuestionById(id, surveyInst.questions[beginIdx]);
}

export const _removePageById = persistToStorage(
  "surveys",
  surveys
)(async function _removePageById(id, beginIdx, endIdx) {
  await _delay(1000);
  const idx = _findSurveyIdx(id);
  const nextQuestions = [...surveys[idx].questions];
  nextQuestions.splice(beginIdx, endIdx - beginIdx);
  surveys[idx].questions = refineQuestions(nextQuestions);
});

export async function copyPageById(id, beginIdx, endIdx) {
  return await Api.createQuestionById(id, "PAGE", 1);
}

export const _copyPageById = persistToStorage(
  "surveys",
  surveys
)(async function _copyPageById(id, beginIdx, endIdx) {
  await _delay(1000);
  const idx = _findSurveyIdx(id);
  const nextQuestions = [
    ...surveys[idx].questions.slice(0, beginIdx),
    ...surveys[idx].questions.slice(beginIdx, endIdx),
    ...surveys[idx].questions
      .slice(beginIdx, endIdx)
      .map((q, idx) => ({ ...q, id: `${new Date().getTime() + idx}` })),
    ...surveys[idx].questions.slice(endIdx),
  ];
  surveys[idx].questions = nextQuestions;
});

export async function fetchLogicList(id, survey, OtherFlag) {
  let logicResp = await Api.fetchLogicList(id);
  if (!survey) {
    survey = await Api.querySurveyById(id, OtherFlag);
  }
  let logicList = LogicRuleMapToModel(survey, logicResp);
  /*
  const foundLogicData = surveyLogicList.find(
    logicData => logicData.surveyId === id
  );
  return foundLogicData
    ? foundLogicData.list.map(data => new LogicRuleModel(data))
    : [];

  let logicList = [];
  const localSurveyData = _findLocalSurveyData(id);
  if (localSurveyData) {
    try {
      logicList = localSurveyData.logicList;
    } catch (err) {
      logicList = [];
    }
  } else {
    const foundLogicData = surveyLogicList.find(
      logicData => logicData.surveyId === id
    );
    logicList = foundLogicData ? foundLogicData.list : [];
  }
  */
  return logicList.map(data => new LogicRuleModel(data));
}

export const createLogicRule = persistToStorage(
  "surveyLogicList",
  surveyLogicList
)(async function createLogicRule(id, type) {
  let data = LogicRuleModel.createData(type);
  // console.log("data:", data);
  return new LogicRuleModel(data);
  // let data = LogicRuleModel.createData(type);
  // const foundLogicDataIdx = surveyLogicList.findIndex(
  //   logicData => logicData.surveyId === id
  // );
  // if (foundLogicDataIdx === -1) {
  //   surveyLogicList.push({
  //     surveyId: id,
  //     list: [data],
  //   });
  // } else {
  //   surveyLogicList[foundLogicDataIdx] = {
  //     ...surveyLogicList[foundLogicDataIdx],
  //     list: [...surveyLogicList[foundLogicDataIdx].list, data],
  //   };
  // }
});

export const insertLogicRule = persistToStorage(
  "surveyLogicList",
  surveyLogicList
)(async function insertLogicRule(data) {
  return await Api.insertLogicRule(data);
});

export const updateLogicRule = persistToStorage(
  "surveyLogicList",
  surveyLogicList
)(async function updateLogicRule(data) {
  return await Api.updateLogicRule(data);
  // const foundLogicDataIdx = surveyLogicList.findIndex(
  //   logicData => logicData.surveyId === id
  // );

  // if (foundLogicDataIdx > -1) {
  //   let logicList = surveyLogicList[foundLogicDataIdx].list;
  //   let idx = logicList.findIndex(logic => logic.id === logicRuleId);
  //   if (idx > -1) {
  //     logicList[idx] = {
  //       ...logicList[idx],
  //       ...data,
  //     };

  //     surveyLogicList[foundLogicDataIdx] = {
  //       ...surveyLogicList[foundLogicDataIdx],
  //       list: logicList,
  //     };
  //   }
  // }
});

export const removeLogicRule = persistToStorage(
  "surveyLogicList",
  surveyLogicList
)(async function removeLogicRule(id, logicRuleId) {
  return await Api.removeLogicRule(id, logicRuleId);
  // const foundLogicDataIdx = surveyLogicList.findIndex(
  //   logicData => logicData.surveyId === id
  // );
  // if (foundLogicDataIdx > -1) {
  //   let logicList = surveyLogicList[foundLogicDataIdx].list;
  //   let idx = logicList.findIndex(logic => logic.id === logicRuleId);
  //   if (idx > -1) {
  //     logicList.splice(idx, 1);
  //     surveyLogicList[foundLogicDataIdx] = {
  //       ...surveyLogicList[foundLogicDataIdx],
  //       list: logicList,
  //     };
  //   }
  // }
});

export async function fetchCollectionList(id) {
  // const foundCollectionInListIndex = surveyCollectionList.findIndex(
  //   s => s.surveyId === id
  // );
  // if (foundCollectionInListIndex === -1) {
  //   return [];
  // }
  // return surveyCollectionList[foundCollectionInListIndex].list; //return an array
  return Api.fetchCollectionList(id);
}

export async function createCollection(id, type) {
  // let foundCollectionInListIndex = surveyCollectionList.findIndex(
  //   s => s.surveyId === id
  // );
  // if (foundCollectionInListIndex === -1) {
  //   surveyCollectionList.push({
  //     surveyId: id,
  //     list: [],
  //   });
  //   foundCollectionInListIndex = surveyCollectionList.findIndex(
  //     s => s.surveyId === id
  //   );
  // }

  // let collectionList = surveyCollectionList[foundCollectionInListIndex].list;

  // const _id = `${new Date().getTime()}`;
  // collectionList.push({
  //   id: _id,
  //   method: type,
  //   created: "2020/03/28 13:22",
  //   updated: "2020/04/28 13:22",
  //   upperBound: 1000,
  //   respCnt: 10,
  //   liveUrl: `https://ecrmsurvey.cht.com.tw/response-live?id=${_id}`,
  //   testUrl: `https://ecrmsurvey.cht.com.tw/response-test?id=${_id}`,
  //   accessList: [
  //     { display: "關懷客戶20200415part1", id: "access-1" },
  //     { display: "關懷客戶20200415part2", id: "access-2" },
  //   ],
  // });

  // surveyCollectionList[foundCollectionInListIndex].list = collectionList;
  return Api.createCollection(id, type);
}

export async function fetchStatisticsData(id, signal) {
  function _generateExtraData(q) {
    let extraData = {};

    if (q.OptionList && Array.isArray(q.OptionList)) {
      extraData.optionStats = q.OptionList.map(o => ({
        ...o,
        respCount: [o.OptionReplyNum, o.OptionReplyPercent],
      }));
    }

    if (
      q.TypeContent === "矩陣題" &&
      q.OptionList &&
      Array.isArray(q.OptionList)
    ) {
      const OptionContentSet = new Set(); //row
      let colsObj = {};
      q.OptionList.map(o => {
        OptionContentSet.add(o.OptionContent);
        colsObj[o.MatrixField] = [];
      });

      q.OptionList.map(o => {
        colsObj[o.MatrixField].push([o.OptionReplyNum, o.OptionReplyPercent]);
      });

      extraData = {
        cols: Object.keys(colsObj),
        rows: [...OptionContentSet],
        optionMatrixStats: colsObj,
      };
    }

    return extraData;
  }

  const statisticsDataFromApi = await Api.fetchStatisticsData(id, signal);

  let typeObject = {
    單選題: "SINGLE_CHOICE",
    基本資料: "BASIC_INFO",
    多選題: "MULTIPLE_CHOICE",
    填空題: "FILL_IN",
    矩陣題: "MATRIX",
  };
  if (
    statisticsDataFromApi &&
    statisticsDataFromApi.live &&
    statisticsDataFromApi.live.QuestionList &&
    statisticsDataFromApi.live.QuestionList
  ) {
    let statisticsData = {
      live: {
        totalRespCount:
          statisticsDataFromApi.live.TotalReplyNum &&
          statisticsDataFromApi.live.TotalReplyNum,
        questions:
          statisticsDataFromApi.live.QuestionList &&
          statisticsDataFromApi.live.QuestionList.map((q, idx) => ({
            ...q,
            idx,
          }))
            .filter(q => q.type !== "PAGE")
            .map(q => {
              let extraData = _generateExtraData(q);
              return new QuestionStatModel(
                {
                  type: typeObject[q.TypeContent],
                  questionId: q.QuestionId,
                  x_Axis: q.X_Axis,
                  respCount: q.QuestionReplyNum,
                  questionTitle: q.QuestionTitle,
                  ...extraData,
                },
                { idx: q.idx }
              );
            })
            .filter(q => q.type !== "PAGE"),
      },
      test: {
        totalRespCount:
          statisticsDataFromApi.test &&
          statisticsDataFromApi.test.TotalReplyNum &&
          statisticsDataFromApi.test.TotalReplyNum,
        questions:
          statisticsDataFromApi.test &&
          statisticsDataFromApi.test.QuestionList &&
          statisticsDataFromApi.test.QuestionList.map((q, idx) => ({
            ...q,
            idx,
          }))
            .filter(q => q.type !== "PAGE")
            .map(q => {
              let extraData = _generateExtraData(q);
              return new QuestionStatModel(
                {
                  type: typeObject[q.TypeContent],
                  questionId: q.QuestionId,
                  x_Axis: q.X_Axis,
                  respCount: q.QuestionReplyNum,
                  questionTitle: q.QuestionTitle,
                  ...extraData,
                },
                { idx: q.idx }
              );
            }),
      },
    };

    return statisticsData;
  }

  return null;
}

export async function surveySignOff(survey) {
  await Api.surveySignOff(survey.id);
}

export async function updateCollection(id, collectionObject) {
  // let foundCollectionInListIndex = surveyCollectionList.findIndex(
  //   s => s.surveyId === id
  // );
  // if (foundCollectionInListIndex === -1) {
  //   return;
  // }

  // let collectionList = surveyCollectionList[foundCollectionInListIndex].list;
  // let idx = collectionList.findIndex(c => c.id === collectionId);
  // if (idx > -1) {
  //   collectionList[idx] = {
  //     ...collectionList[idx],
  //     ...collectionObject,
  //   };
  // }

  // surveyCollectionList[foundCollectionInListIndex].list = collectionList;

  return Api.updateCollection(id, collectionObject);
}

export async function deleteCollection(id, provideType) {
  // let foundCollectionInListIndex = surveyCollectionList.findIndex(
  //   s => s.surveyId === id
  // );
  // if (foundCollectionInListIndex === -1) {
  //   return;
  // }

  // let collectionList = surveyCollectionList[foundCollectionInListIndex].list;
  // let idx = collectionList.findIndex(c => c.id === collectionId);
  // if (idx > -1) {
  //   collectionList.splice(idx, 1);
  // }

  // surveyCollectionList[foundCollectionInListIndex].list = collectionList;
  return Api.deleteCollection(id, provideType);
}

/** 取得交叉選項回覆數量限制 資料 */
export async function fetchLimitData(surveyId, provideType) {
  return Api.fetchLimitData(surveyId, provideType);
}

/** 更新 交叉選項回覆數量限制 資料 */
export async function updateLimitData(surveyId, provideType, data) {
  return Api.updateLimitData(surveyId, provideType, data);
}

/** 刪除 交叉選項回覆數量限制 資料 */
export async function deleteLimitData(surveyId, provideType) {
  return Api.deleteLimitData(surveyId, provideType);
}

/** 取得交叉選項回覆數量限制 分析資料 (驗證預覽Token) */
export async function fetchLimitStatisticData(surveyId, provideType, test) {
  return Api.fetchLimitStatisticData(surveyId, provideType, test);
}

/** 取得交叉選項回覆數量限制 分析資料 (驗證填寫Token)*/
export async function fetchOutSideLimitStatisticData(
  surveyId,
  provideType,
  test
) {
  return Api.fetchOutSideLimitStatisticData(surveyId, provideType, test);
}

export async function fetchEndPage(id) {
  return Api.fetchEndPage(id);
}

export async function editEndPage(props) {
  return Api.editEndPage(props);
}

export async function createEndPage(props) {
  return Api.createEndPage(props);
}

// 2022-08-09 ALVIN 歡迎頁APIs
export async function fetchWelcomePage(id) {
  return Api.fetchWelcomePage(id);
}

export async function editWelcomePage(props) {
  return Api.editWelcomePage(props);
}

export async function createWelcomePage(props) {
  return Api.createWelcomePage(props);
}

export async function fetchAccountListNonAdmin() {
  return Api.fetchAccountListNonAdmin();
}
export async function fetchAccountList(
  token,
  page,
  perPage,
  filter,
  query = null
) {
  return Api.fetchAccountList(token, page, perPage, filter, query);
}

export async function submitAccount(props) {
  return Api.submitAccount(props);
}

export async function fetchTeamList() {
  return Api.fetchTeamList();
}

export async function fetchMyTeamList(code) {
  return Api.fetchMyTeamList(code);
}

export async function fetchTeamUsers(id) {
  return Api.fetchTeamUsers(id);
}

export async function queryTeamById(id) {
  return Api.queryTeamById(id);
}

export async function submitTeam(props) {
  return Api.submitTeam(props);
}

export async function submitTeamSurveys(props) {
  return Api.submitTeamSurveys(props);
}

export async function submitTeamUsers(props) {
  return Api.submitTeamUsers(props);
}

export async function submitTeamOwner(props) {
  return Api.submitTeamOwner(props);
}

export async function deleteTeam(teamId) {
  return Api.deleteTeam(teamId);
}

export async function fetchTemplateList() {
  return Api.fetchTemplateList();
}

// 取得範本類別列表對應的範本
export async function fetchTemplateByCategory(category) {
  return Api.fetchTemplateByCategory(category);
}

export async function copyFromTemplate(templateId, surveyId) {
  // const foundSurveyIdx = surveys.findIndex(s => s.id === surveyId);
  // const foundTemplateIdx = templateList.findIndex(t => t.id === templateId);

  // if (foundSurveyIdx === -1 || foundTemplateIdx === -1) {
  //   throw new Error("API fail");
  // }

  // surveys[foundSurveyIdx] = {
  //   ...templateList[foundTemplateIdx].survey,
  //   id: surveys[foundSurveyIdx].id,
  // };
  // // TODO: surveyLogicList, collectionList
  // return new SurveyModel(surveys[foundSurveyIdx]);

  return Api.copyFromTemplate(templateId, surveyId);
}

export async function deleteTemplate(templateId) {
  return Api.deleteTemplate(templateId);
}

export async function sendCopyToUser(surveyId, userId) {
  // return { userId, surveyId };
  return Api.sendCopyToUser(surveyId, userId);
}

export async function createSurveyFromTemplate(surveyId, templateId) {
  return { surveyId, templateId };
}

export async function updateTemplate(id, prop) {
  return Api.updateTemplate(id, prop);
}

export async function saveToMyTemplate(surveyId, title) {
  // const foundSurvey = surveys.find(s => s.id === surveyId);
  // const newTemplate = {
  //   id: `${new Date().getTime()}`,
  //   title,
  //   questionCount: foundSurvey.questions.filter(q => q.type !== "PAGE").length,
  //   survey: { ...foundSurvey, title },
  // };

  // templateList.push(newTemplate);

  // return new TemplateModel(newTemplate);
  return Api.saveToMyTemplate(surveyId, title);
}

// 新增至共用範本
export async function saveToPublicTemplate(surveyId, title, templateCategory) {
  return Api.saveToPublicTemplate(surveyId, title, templateCategory);
}

export async function getExternalUserToken(
  surveyId,
  surveyEnv,
  provideType,
  p
) {
  return Api.getExternalUserToken(surveyId, surveyEnv, provideType, p);
}

export async function externalLoginVerify(
  surveyId,
  provideType,
  validField,
  validData,
  accessToken = ""
) {
  return Api.externalLoginVerify(
    surveyId,
    provideType,
    validField,
    validData,
    accessToken
  );
}

export async function fetchExternalSurvey(id, OtherFlag) {
  let resp = await Api.fetchExternalSurvey(id, OtherFlag);
  if (resp.code === "200") {
    let logicList = JSON.parse(JSON.stringify(resp.logicList));
    let survey = resp.survey;
    resp.logicList = LogicRuleMapToModel(survey, logicList).map(
      data => new LogicRuleModel(data)
    );
    resp.survey = new SurveyModel(resp.survey);
  }

  return resp;
}

// 取得預覽範本
export async function fetchTemplateSurvey(id, OtherFlag) {
  let resp = await Api.fetchTemplateSurvey(id, OtherFlag);
  if (resp.code === "200") {
    let logicList = JSON.parse(JSON.stringify(resp.logicList));
    let survey = resp.survey;
    resp.logicList = LogicRuleMapToModel(survey, logicList).map(
      data => new LogicRuleModel(data)
    );
    resp.survey = new SurveyModel(resp.survey);
  }

  return resp;
}

// 2022-05-16: 新增問卷發生異常提示對話框
// 新增useCatchAlert參數控制是需要顯示特殊對話框
export async function submitAnswer(data, useCatchAlert) {
  let resp = await Api.submitAnswer(data, useCatchAlert);
  return resp;
}

// 2022-08-06 ALVIN
export async function submitPrivacy(data, useCatchAlert) {
  let resp = await Api.submitPrivacy(data, useCatchAlert);
  return resp;
}

// 選項類 - 企業主題選單
export async function fetchThemeList() {
  return Api.fetchThemeList();
}
export async function _fetchThemeList() {
  await _delay(200);
  return {};
}

// 選項類 - 數字總和題單位選單
export async function fetchUnitList() {
  return Api.fetchUnitList();
}
export async function _fetchUnitList() {
  await _delay(200);
  return {};
}

// 選項類 - 字型選單
export async function fetchFontList() {
  return Api.fetchFontList();
}
export async function _fetchFontList() {
  await _delay(200);
  return {};
}

// 選項類 - 共用範本類別
export async function fetchTemplateCategory() {
  return Api.fetchTemplateCategory();
}

// 取得範本資料依照ID
export async function fetchTemplateDataById(code) {
  return Api.fetchTemplateDataById(code);
}

// 設定類 - 取得取得編輯時間限制
export async function fetchEditTimeLimit() {
  return Api.fetchEditTimeLimit();
}

// 共同編輯 - 確認編輯狀態
export async function fetchLock(surveyId) {
  return Api.fetchLock(surveyId);
}

// 選項類 - 共用範本類別
export async function fetchUnlock(surveyId) {
  return Api.fetchUnlock(surveyId);
}
// 個資資料
// 取得個資資料列表
export async function fetchPrivacyList(token) {
  return Api.queryPrivacyList(token);
}

export async function setPrivacyEnable(privacyId) {
  return Api.enablePrivacy(privacyId);
}

export async function deletePrivacy(privacyId) {
  return Api.deletePrivacy(privacyId);
}

export async function clonePrivacy(privacyId, title) {
  return Api.clonePrivacy(privacyId, title);
}

export async function updatePrivacy(data) {
  return Api.updatePrivacy(data);
}

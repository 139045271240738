import React from "react";
import * as SurveyApi from "../SurveyApi";
import { Color } from "../Components/Widget";
import constants from "../constants";
import { d2s } from "../Utils/DateUtil";
import IdleTimer from "react-idle-timer";

const Context = React.createContext();
const Actions = {};
/**
 * 來源為 : /api/Survey/Status/List
 * 1:建立中
 * 2:主管審核中
 * 3:執行單位審核中
 * 4:修改中
 * 5:已決行
 * 6:執行中
 * 7:已完成
 * 8:已取消
 */
const Utils = {
  getStatusLabel(survey, surveyStatusList) {
    const foundStatus = surveyStatusList.find(s => s.status === survey.status);
    const display = foundStatus ? foundStatus.description : "-";
    if (survey.status === "1") {
      return [display, Color.LightGreen];
    } else if (survey.status === "2") {
      return [display, Color.LightMagenta];
    } else if (survey.status === "3") {
      return [display, Color.LightMagenta];
    } else if (survey.status === "4") {
      return [display, Color.LightGreen];
    } else if (survey.status === "5") {
      return [display, Color.LightMagenta];
    } else if (survey.status === "6") {
      return [display, Color.LightMagenta];
    } else if (survey.status === "7") {
      return [display, Color.LightBlue];
    }

    return [display || survey.status, Color.LightBlue];
  },

  getValidPeriod(survey) {
    return `${d2s(survey.startDate) || "-"} 至 ${d2s(survey.endDate) || "-"}`;
  },
};

const LIVE = !constants.useMock;
const queryString = require("query-string");
const externalIdleTimerDuration = constants.externalIdleTimerDuration;
const internalIdleTimerDuration = constants.internalIdleTimerDuration;
class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteOnCancel: false,
      deleteLogicOnCancel: false,
      deleteCollectionOnCancel: false,
      isExternalUserLogin: false,
      ifLoggedInExternalPage: false,
      ifLoggedInInternalPage: false,
      tokenValid: false,
    };

    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);

    Actions.setInternalLocalStorageToken = value => {
      this.setState({ ifLoggedInInternalPage: value });
    };

    Actions.setExternalLocalStorageToken = value => {
      this.setState({ ifLoggedInExternalPage: value });
    };

    Actions.setExternalUserLogin = value => {
      this.setState({ isExternalUserLogin: value });
    };

    Actions.shouldRenderExternalSurvey = value => {
      return this.state.isExternalUserLogin;
    };

    Actions.setDeleteOnCancel = value => {
      this.setState({ deleteOnCancel: value });
    };

    Actions.shouldDeleteOnCancel = () => {
      return this.state.deleteOnCancel;
    };

    Actions.setDeleteLogicOnCancel = value => {
      this.setState({ deleteLogicOnCancel: value });
    };

    Actions.shouldDeleteLogicOnCancel = () => {
      return this.state.deleteLogicOnCancel;
    };

    Actions.setDeleteCollectionOnCancel = value => {
      this.setState({ deleteCollectionOnCancel: value });
    };

    Actions.shouldDeleteCollectionOnCancel = () => {
      return this.state.deleteCollectionOnCancel;
    };

    Actions.fetchMyAllSurveys = async (
      profile,
      page,
      perPage,
      queryInfo,
      search
    ) => {
      if (LIVE) {
        return SurveyApi.fetchMyAllSurveys(
          profile,
          page,
          perPage,
          queryInfo,
          search
        );
      }
      return SurveyApi._fetchMyAllSurveys();
    };
    Actions.fetchMySurveys = async (
      profile,
      page,
      perPage,
      queryInfo,
      search
    ) => {
      if (LIVE) {
        return SurveyApi.fetchMySurveys(
          profile,
          page,
          perPage,
          queryInfo,
          search
        );
      }
      return SurveyApi._fetchMySurveys();
    };

    Actions.fetchTeamSurveys = async (
      profile,
      teamId,
      page,
      perPage,
      queryInfo,
      search
    ) => {
      if (LIVE) {
        return SurveyApi.fetchTeamSurveys(
          profile,
          teamId,
          page,
          perPage,
          queryInfo,
          search
        );
      }
      return SurveyApi._fetchTeamSurveys(profile, teamId);
    };

    Actions.fetchMyOwnSurveys = async (
      profile,
      page,
      perPage,
      queryInfo,
      search
    ) => {
      if (LIVE) {
        return SurveyApi.fetchMyOwnSurveys(
          profile,
          page,
          perPage,
          queryInfo,
          search
        );
      }
      return SurveyApi._fetchMyOwnSurveys(profile);
    };

    Actions.fetchSharedSurveys = async (
      profile,
      page,
      perPage,
      queryInfo,
      search
    ) => {
      if (LIVE) {
        return SurveyApi.fetchSharedSurveys(
          profile,
          page,
          perPage,
          queryInfo,
          search
        );
      }
      return SurveyApi._fetchSharedSurveys(profile);
    };

    Actions.fetchSurveyStatus = async () => {
      if (LIVE) {
        return SurveyApi.fetchSurveyStatus();
      }
      return SurveyApi._fetchSurveyStatus();
    };

    Actions.fetchById = async (id, OtherFlag) => {
      if (LIVE) {
        return SurveyApi.fetchById(id, OtherFlag);
      }
      return SurveyApi._fetchById(id, OtherFlag);
    };

    Actions.fetchNoPicById = async (id, OtherFlag) => {
      if (LIVE) {
        return SurveyApi.fetchNoPicById(id, OtherFlag);
      }
      return SurveyApi._fetchById(id, OtherFlag);
    };

    Actions.createSurvey = async (profile, survey) => {
      if (LIVE) {
        return SurveyApi.createSurvey(profile, survey);
      }
      return SurveyApi._createSurvey(profile, survey);
    };

    Actions.updatePropertyById = async (id, prop, unModelSurvey) => {
      if (LIVE) {
        return SurveyApi.updatePropertyById(id, prop, unModelSurvey);
      }
      return SurveyApi._updatePropertyById(id, prop, unModelSurvey);
    };

    Actions.updateThemePropertyById = async (id, prop) => {
      if (LIVE) {
        return SurveyApi.updateThemePropertyById(id, prop);
      }
      return SurveyApi._updateThemePropertyById(id, prop);
    };

    Actions.updateThemePropertyByIdAndReturnSurveyModel = async (
      id,
      prop,
      unModelSurvey
    ) => {
      return SurveyApi.updateThemePropertyByIdAndReturnSurveyModel(
        id,
        prop,
        unModelSurvey
      );
    };

    Actions.createQuestionById = async (id, questionType, pageNo = 1) => {
      // note: frontend always bring pageNo to backend as 1, and frontend don't depends on pageNo to calculate page.
      if (questionType !== "PAGE") {
        this.setState({ deleteOnCancel: true });
      }

      if (LIVE) {
        return SurveyApi.createQuestionById(id, questionType, pageNo);
      }
      return SurveyApi._createQuestionById(id, questionType, pageNo);
    };

    Actions.deleteQuestionById = async (id, questionIdx, questionInst) => {
      try {
        if (LIVE) {
          await SurveyApi.deleteQuestionById(id, questionIdx, questionInst);
        }
        await SurveyApi._deleteQuestionById(id, questionIdx);
      } catch (ex) {}

      this.setState({ deleteOnCancel: false });
    };

    Actions.updateQuestionById = async (
      id,
      questionIdx,
      questionInst,
      nextQuestion
    ) => {
      this.setState({ deleteOnCancel: false });

      if (LIVE) {
        return SurveyApi.updateQuestionById(
          id,
          questionIdx,
          questionInst,
          nextQuestion
        );
      }
      return SurveyApi._updateQuestionById(id, questionIdx, nextQuestion);
    };

    Actions.reorderQuestionById = async (id, srcIdx, destIdx, surveyInst) => {
      if (LIVE) {
        return SurveyApi.reorderQuestionById(id, srcIdx, destIdx, surveyInst);
      }

      return SurveyApi._reorderQuestionById(id, srcIdx, destIdx);
    };

    Actions.copyQuestionById = async (id, questionIdx, questionInst) => {
      if (LIVE) {
        return SurveyApi.copyQuestionById(id, questionIdx, questionInst);
      }

      return SurveyApi._copyQuestionById(id, questionIdx);
    };

    Actions.removePageById = async (id, beginIdx, endIdx, surveyInst) => {
      if (LIVE) {
        return SurveyApi.removePageById(id, beginIdx, endIdx, surveyInst);
      }

      return SurveyApi._removePageById(id, beginIdx, endIdx);
    };

    Actions.copyPageById = async (id, beginIdx, endIdx) => {
      if (LIVE) {
        return SurveyApi.copyPageById(id, beginIdx, endIdx);
      }

      return SurveyApi._copyPageById(id, beginIdx, endIdx);
    };

    Actions.fetchLogicList = async (id, survey) => {
      return SurveyApi.fetchLogicList(id, survey, true);
    };

    Actions.createLogicRule = async (id, type) => {
      this.setState({ deleteLogicOnCancel: true });
      return SurveyApi.createLogicRule(id, type);
    };

    Actions.insertLogicRule = async data => {
      this.setState({ deleteLogicOnCancel: false });
      return SurveyApi.insertLogicRule(data);
    };

    Actions.updateLogicRule = async data => {
      this.setState({ deleteLogicOnCancel: false });
      return SurveyApi.updateLogicRule(data);
    };

    Actions.removeLogicRule = async (id, logicRuleId) => {
      return SurveyApi.removeLogicRule(id, logicRuleId);
    };

    Actions.fetchCollectionList = async id => {
      return SurveyApi.fetchCollectionList(id);
    };

    Actions.createCollection = async (id, type) => {
      this.setState({ deleteCollectionOnCancel: true });
      return SurveyApi.createCollection(id, type);
    };

    Actions.updateCollection = async (id, cid, props) => {
      this.setState({ deleteCollectionOnCancel: false });
      return SurveyApi.updateCollection(id, cid, props);
    };

    Actions.deleteCollection = async (id, cid) => {
      return SurveyApi.deleteCollection(id, cid);
    };

    /** 取得 交叉選項回覆數量限制 資料 */
    Actions.fetchLimitData = async (surveyId, provideType) => {
      return SurveyApi.fetchLimitData(surveyId, provideType);
    };

    /** 更新 交叉選項回覆數量限制 資料 */
    Actions.updateLimitData = async (surveyId, provideType, data) => {
      return SurveyApi.updateLimitData(surveyId, provideType, data);
    };

    /** 刪除 交叉選項回覆數量限制 資料 */
    Actions.deleteLimitData = async (surveyId, provideType) => {
      return SurveyApi.deleteLimitData(surveyId, provideType);
    };

    /** 取得交叉選項回覆數量限制 分析資料 (驗證預覽Token) */
    Actions.fetchLimitStatisticData = async (surveyId, provideType, test) => {
      return SurveyApi.fetchLimitStatisticData(surveyId, provideType, test);
    };

    /** 取得交叉選項回覆數量限制 分析資料 (驗證填寫Token)*/
    Actions.fetchOutSideLimitStatisticData = async (
      surveyId,
      provideType,
      test
    ) => {
      return SurveyApi.fetchOutSideLimitStatisticData(
        surveyId,
        provideType,
        test
      );
    };

    Actions.fetchStatisticsData = async (id, signal) => {
      return SurveyApi.fetchStatisticsData(id, signal);
    };

    Actions.fetchAccountListNonAdmin = async () => {
      return SurveyApi.fetchAccountListNonAdmin();
    };
    Actions.fetchAccountList = async (
      profile,
      page,
      perPage,
      filter,
      queryInfo
    ) => {
      return SurveyApi.fetchAccountList(
        profile,
        page,
        perPage,
        filter,
        queryInfo
      );
    };

    Actions.submitAccount = async data => {
      return SurveyApi.submitAccount(data);
    };

    Actions.fetchTeamList = async () => {
      return SurveyApi.fetchTeamList();
    };

    Actions.fetchMyTeamList = async userCode => {
      return SurveyApi.fetchMyTeamList(userCode);
    };

    Actions.fetchTeamUsers = async teamId => {
      return SurveyApi.fetchTeamUsers(teamId);
    };

    Actions.queryTeamById = async teamId => {
      return SurveyApi.queryTeamById(teamId);
    };

    Actions.submitTeam = async data => {
      return SurveyApi.submitTeam(data);
    };

    Actions.submitTeamSurveys = async data => {
      return SurveyApi.submitTeamSurveys(data);
    };

    Actions.submitTeamUsers = async data => {
      return SurveyApi.submitTeamUsers(data);
    };

    Actions.submitTeamOwner = async data => {
      return SurveyApi.submitTeamOwner(data);
    };

    Actions.deleteTeam = async teamId => {
      return SurveyApi.deleteTeam(teamId);
    };

    Actions.surveySignOff = async survey => {
      return SurveyApi.surveySignOff(survey);
    };

    Actions.fetchTemplateList = async () => {
      return SurveyApi.fetchTemplateList();
    };

    // 取得範本類別列表對應的範本
    Actions.fetchTemplateByCategory = async category => {
      return SurveyApi.fetchTemplateByCategory(category);
    };

    Actions.copyFromTemplate = async (templateId, surveyId) => {
      return SurveyApi.copyFromTemplate(templateId, surveyId);
    };

    Actions.deleteTemplate = async templateId => {
      return SurveyApi.deleteTemplate(templateId);
    };

    Actions.sendCopyToUser = async (surveyId, userId) => {
      return SurveyApi.sendCopyToUser(surveyId, userId);
    };

    Actions.saveToMyTemplate = async (surveyId, title) => {
      return SurveyApi.saveToMyTemplate(surveyId, title);
    };

    // 新增至共用範本
    Actions.saveToPublicTemplate = async (
      surveyId,
      title,
      templateCategory
    ) => {
      return SurveyApi.saveToPublicTemplate(surveyId, title, templateCategory);
    };

    Actions.createSurveyFromTemplate = async (surveyId, templateId) => {
      return SurveyApi.createSurveyFromTemplate(surveyId, templateId);
    };

    Actions.updateTemplate = async (id, prop) => {
      return SurveyApi.updateTemplate(id, prop);
    };

    Actions.fetchEndingPageById = async id => {
      return SurveyApi.fetchEndingPageById(id);
    };

    // 2022-08-09 ALVIN 歡迎頁APIs
    Actions.fetchWelcomePageById = async id => {
      return SurveyApi.fetchWelcomePageById(id);
    };

    Actions.getExternalUserToken = async (
      surveyId,
      surveyEnv,
      provideType,
      p = ""
    ) => {
      return SurveyApi.getExternalUserToken(
        surveyId,
        surveyEnv,
        provideType,
        p
      );
    };

    Actions.externalLoginVerify = async (
      surveyId,
      provideType,
      validField,
      validData,
      accessToken
    ) => {
      return SurveyApi.externalLoginVerify(
        surveyId,
        provideType,
        validField,
        validData,
        accessToken
      );
    };

    Actions.fetchExternalSurvey = async (id, OtherFlag) => {
      return SurveyApi.fetchExternalSurvey(id, OtherFlag);
    };

    // 取得預覽範本
    Actions.fetchTemplateSurvey = async (id, OtherFlag) => {
      return SurveyApi.fetchTemplateSurvey(id, OtherFlag);
    };

    Actions.fetchEndPage = async data => {
      return SurveyApi.fetchEndPage(data);
    };

    Actions.createEndPage = async data => {
      return SurveyApi.createEndPage(data);
    };

    Actions.editEndPage = async data => {
      return SurveyApi.editEndPage(data);
    };

    // 2022-08-09 ALVIN 歡迎頁APIs
    Actions.fetchWelcomePage = async data => {
      return SurveyApi.fetchWelcomePage(data);
    };

    Actions.createWelcomePage = async data => {
      return SurveyApi.createWelcomePage(data);
    };

    Actions.editWelcomePage = async data => {
      return SurveyApi.editWelcomePage(data);
    };

    // 2022-05-16: 新增問卷發生異常提示對話框
    // 新增useCatchAlert參數控制是需要顯示特殊對話框
    Actions.submitAnswer = async (data, useCatchAlert) => {
      // return ''
      return SurveyApi.submitAnswer(data, useCatchAlert);
    };

    // 2022-08-06 ALVIN 個資同意API
    Actions.submitPrivacy = async (data, useCatchAlert) => {
      return SurveyApi.submitPrivacy(data, useCatchAlert);
    };

    // 選項類 - 企業主題選單
    Actions.fetchThemeList = async () => {
      if (LIVE) {
        return SurveyApi.fetchThemeList();
      }
      return SurveyApi._fetchThemeList();
    };
    // 選項類 - 數字總和題單位選單
    Actions.fetchUnitList = async () => {
      if (LIVE) {
        return SurveyApi.fetchUnitList();
      }
      return SurveyApi._fetchUnitList();
    };
    // 選項類 - 字型選單
    Actions.fetchFontList = async () => {
      if (LIVE) {
        return SurveyApi.fetchFontList();
      }
      return SurveyApi._fetchFontList();
    };

    // 選項類 - 共用範本類別
    Actions.fetchTemplateCategory = async () => {
      return SurveyApi.fetchTemplateCategory();
    };

    // 範本資料依照ID
    Actions.fetchTemplateDataById = async code => {
      return SurveyApi.fetchTemplateDataById(code);
    };

    // 設定類 - 取得取得編輯時間限制
    Actions.fetchEditTimeLimit = async () => {
      return SurveyApi.fetchEditTimeLimit();
    };

    // 共同編輯 - 確認編輯狀態
    Actions.fetchLock = async surveyId => {
      return SurveyApi.fetchLock(surveyId);
    };

    // 共同編輯 - 釋放編輯狀態
    Actions.fetchUnlock = async surveyId => {
      return SurveyApi.fetchUnlock(surveyId);
    };

    // 個資
    Actions.fetchPrivacyList = async () => {
      // if (LIVE) {
      return SurveyApi.fetchPrivacyList();
      // }
      // return SurveyApi._fetchMySurveys();
    };
    Actions.setPrivacyEnable = async privacyId => {
      return SurveyApi.setPrivacyEnable(privacyId);
    };
    Actions.deletePrivacy = async privacyId => {
      return SurveyApi.deletePrivacy(privacyId);
    };
    Actions.clonePrivacy = async (privacyId, title) => {
      return SurveyApi.clonePrivacy(privacyId, title);
    };
    Actions.updatePrivacy = async data => {
      return SurveyApi.updatePrivacy(data);
    };
  }

  componentDidMount() {
    async function validation() {
      let options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.token}`,
        },
      };

      try {
        const resp = await fetch(`${constants.apiUrl}/api/system/tokenAuth`, {
          ...options,
        });

        const json = await resp.json();
        return json;
      } catch (ex) {
        // console.warn(ex);
      }
    }
    let resp;
    const pathname = window.location.pathname;
    if (
      pathname !== "/q/" &&
      pathname !== "/q" &&
      window.localStorage.token !== undefined
    ) {
      resp = validation().then(resp => {
        if (resp.code && (resp.code === "406" || resp.code === "-1")) {
          this.setState(
            state => {
              return {
                tokenValid: false,
              };
            },
            () => {
              let hostname = window.location.host;
              // check host url to set the token Valid by state.
              //判斷是否在可運行的網址環境並確認token狀態
              if (
                hostname === "localhost:8000" || // 本地端環境
                hostname === "localhost:9000" || // 本地端環境
                hostname === "139.162.67.143:8080" || // 測試網址環境
                hostname === "ecrmsurvey.cht.com.tw" || // 中華正式環境
                hostname === "crmsurvey.cht.com.tw" // 中華正式環境
              ) {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("profile");
                window.location.replace("/");
                //navigate("/", { replace: true });
                this.setState({ tokenValid: false });
              }
            }
          );
        } else if (resp.code === "200") {
          this.setState({ tokenValid: true });
        }
      });
    } else {
      this.setState({ tokenValid: true });
    }
  }

  componentDidUpdate() {
    if (
      !window.localStorage.getItem("token") &&
      typeof this.props.children !== "undefined" &&
      typeof this.props.children.props !== "undefined" &&
      (queryString.parse(window.location.search).status === "ending" ||
        queryString.parse(window.location.search).status === "force_quit" ||
        this.props.children.props.surveyStatus === 1 ||
        this.props.children.props.surveyStatus === 3 ||
        this.props.children.props.surveyStatus === 4 ||
        this.props.children.props.surveyStatus === 5 ||
        this.props.children.props.surveyStatus === 6 ||
        !this.state.ifLoggedInExternalPage ||
        !this.state.ifLoggedInInternalPage)
    ) {
      this.idleTimer.pause();
    } else if (
      this.state.ifLoggedInExternalPage ||
      this.state.ifLoggedInInternalPage
    ) {
      this.idleTimer.resume();
    }
  }

  handleOnAction(event) {
    // console.log('user did something', event)
  }
  handleOnActive(event) {
    // console.log("user is active", event);
    // console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  handleOnIdle(event) {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("verifyInfo");
    window.alert("操作逾時，系統將自動為您登出");
    window.location.reload();
  }

  render() {
    return (
      this.state.tokenValid &&
      (this.state.ifLoggedInExternalPage === false &&
      this.state.ifLoggedInInternalPage === false ? (
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={1000 * externalIdleTimerDuration}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        >
          <Context.Provider value={this.state}>
            {this.props.children}
          </Context.Provider>
        </IdleTimer>
      ) : this.state.ifLoggedInExternalPage ? (
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={1000 * externalIdleTimerDuration}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        >
          <Context.Provider value={this.state}>
            {this.props.children}
          </Context.Provider>
        </IdleTimer>
      ) : (
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={1000 * internalIdleTimerDuration}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        >
          <Context.Provider value={this.state}>
            {this.props.children}
          </Context.Provider>
        </IdleTimer>
      ))
    );
  }
}

class Consumer extends React.Component {
  render() {
    return (
      <Context.Consumer>{state => this.props.children(state)}</Context.Consumer>
    );
  }
}

function withConsumer(Comp) {
  class WrappedComp extends React.Component {
    render() {
      return (
        <Consumer>{state => <Comp survey={state} {...this.props} />}</Consumer>
      );
    }
  }

  WrappedComp.displayName = `WithSurvey-${Comp.displayName}`;
  return WrappedComp;
}

export { Provider, Consumer, withConsumer, Actions, Utils };
